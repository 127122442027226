.approval-font-bold {
  font-family: "Tahoma" !important;
  src: url("./tahoma/Tahoma-Bold.eot");
  src: local("Tahoma Bold"), local("Tahoma-Bold"),
    url("./tahoma/Tahoma-Bold.eot?#iefix") format("embedded-opentype"),
    url("./tahoma/Tahoma-Bold.woff2") format("woff2"),
    url("./tahoma/Tahoma-Bold.woff") format("woff"),
    url("./tahoma/Tahoma-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-size: 14;
}

.approval-font {
  font-family: "Tahoma" !important;
  src: url("./tahoma/Tahoma.eot");
  src: local("Tahoma"),
    url("./tahoma/Tahoma.eot?#iefix") format("embedded-opentype"),
    url("./tahoma/Tahoma.woff2") format("woff2"),
    url("./tahoma/Tahoma.woff") format("woff"),
    url("./tahoma/Tahoma.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-size: 14;
}

.travel-table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd;
}

.travel-table th,
.travel-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.travel-table th {
  background-color: #f2f2f2;
}
